import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const TitleWrapper = styled(Link)`
  font-family: "Raleway", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.common.white};
  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.palette.primary.light} 0%,${theme.palette.primary.main} 100%)`};
  &:hover {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }
`;
